<template>
  <template-common-form
    class="form_pwd"
    :title-txt="titleText"
    :sub-title-txt="subTitleText"
  >
    <template v-if="hasProfile">
      <div class="row_form">
        <label class="txt_form" for="nowPwd">현재 비밀번호</label>
        <atoms-c-input
          id="nowPwd"
          v-model="nowPwd"
          type="password"
          class="input_form"
          maxlength="20"
          :placeholder="PWD_MSG_OBJ.PWD_NOW_PLACEHOLDER"
          required
        />
      </div>
    </template>
    <div class="row_form">
      <label class="txt_form" for="pwd">
        새 비밀번호
        <atoms-c-icon v-if="validStateMap['pwd'].err" icon-name="fail" />
        <atoms-c-icon v-if="validStateMap['pwd'].valid" icon-name="success" />
      </label>

      <atoms-c-input
        id="pwd"
        v-model="pwd"
        class="input_form"
        type="password"
        maxlength="20"
        :placeholder="newPwdPlaceholder"
        :error="validStateMap['pwd'].err"
        required
        @keyup="beforeKeyUp('pwd', $event)"
      />
    </div>
    <div class="row_form row_form_m">
      <label class="txt_form"></label>
      <div class="input_form">
        <p
          v-if="validStateMap['pwd'].msg"
          :class="{
            txt_err: validStateMap['pwd'].err,
            txt_valid: validStateMap['pwd'].valid
          }"
        >
          {{ validStateMap['pwd'].msg }}
        </p>
      </div>
    </div>
    <div class="row_form">
      <label class="txt_form" for="rePwd">
        새 비밀번호 확인
        <atoms-c-icon v-if="validStateMap['rePwd'].err" icon-name="fail" />
        <atoms-c-icon v-if="validStateMap['rePwd'].valid" icon-name="success" />
      </label>

      <atoms-c-input
        id="rePwd"
        v-model="rePwd"
        class="input_form"
        type="password"
        maxlength="20"
        :placeholder="newRePwdPlaceholder"
        :error="validStateMap['rePwd'].err"
        required
        @keyup="beforeKeyUp('rePwd', $event)"
      />
    </div>
    <div class="row_form row_form_m">
      <label class="txt_form"></label>
      <div class="input_form">
        <p
          v-if="validStateMap['rePwd'].msg"
          :class="{
            txt_err: validStateMap['rePwd'].err,
            txt_valid: validStateMap['rePwd'].valid
          }"
        >
          {{ validStateMap['rePwd'].msg }}
        </p>
      </div>
    </div>
    <hr />

    <div class="wrap_btn">
      <template v-if="hasProfile">
        <atoms-border-button
          class="btn_cancel"
          append-icon="chevron_right_rouned"
          color-pack="salamon"
          text="취소하기"
          @click="goToBack"
        />
        <atoms-border-button
          class="btn_next"
          append-icon="chevron_right_rouned"
          type="submit"
          :text="btnText"
          :disabled="!isAllValid"
          @click="updatePassword"
        />
      </template>
      <atoms-default-button
        v-else
        class="btn_next"
        type="submit"
        :text="btnText"
        :disabled="!isAllValid"
        @click="updatePassword"
      ></atoms-default-button>
    </div>
  </template-common-form>
</template>
<script lang="ts" setup>
import { debounce } from 'lodash-es'
import {
  PWD_MSG_OBJ,
  PWD_VALIDATOR,
  ERR_MSG_OBJ,
  DEFAULT_PHASE,
  COMPLETE_PHASE
} from '@/utils/constants'

const props = defineProps({
  pwdToken: {
    type: String,
    default: ''
  },
  agreedTerms: {
    type: Array,
    default: () => []
  }
})
const { $toast } = useNuxtApp()
const fromPath = useState('fromPath')
const { setProfile, setTerms } = useAuthStore()
const { hasProfile } = storeToRefs(useAuthStore())
const { $api } = useNuxtApp()
const router = useRouter()
const nowPwd = ref<string>('')
const pwd = ref<string>('')
const rePwd = ref<string>('')

const debounceTimer: {
  [key: string]: ReturnType<typeof setTimeout> | undefined
} = reactive({ pwd: undefined, rePwd: undefined })

const beforeKeyUp = (id: string, e: KeyboardEvent) => {
  if (e.code === 'Tab' || e.keyCode === 9) return
  if (debounceTimer[id]) {
    clearTimeout(debounceTimer[id])
  }

  debounceTimer[id] = setTimeout(() => onKeyUp(e), 500)
}

const validStateMap: {
  [key: string]: { err: boolean; valid: boolean; msg: string }
} = reactive({
  pwd: { err: false, valid: false, msg: '' },
  rePwd: { err: false, valid: false, msg: '' }
})

// 설정페이지 변경하기
// 최초로그인 (학급계정) 다음으로
// 비밀번호 찾기 변경하기
const btnText = computed(() => {
  if (hasProfile.value || props.pwdToken) {
    return '변경하기 '
  } else {
    return '다음으로'
  }
})

const newPwdPlaceholder = computed(() => {
  return hasProfile.value
    ? PWD_MSG_OBJ.PWD_NEW_PLACEHOLDER
    : PWD_MSG_OBJ.PWD_PLACEHOLDER
})
const newRePwdPlaceholder = computed(() => {
  return hasProfile.value
    ? PWD_MSG_OBJ.PWD_NEW_RE_PLACEHOLDER
    : PWD_MSG_OBJ.PWD_RE_PLACEHOLDER
})

const isAllValid = computed(() => {
  return (
    Object.values(validStateMap).every((v) => v.valid) &&
    (hasProfile.value ? nowPwd.value.length > 0 : true)
  )
})
const titleText = computed(
  () => `비밀번호 ${hasProfile.value ? '변경' : '재설정'}`
)
const subTitleText = computed(() => {
  return props.agreedTerms.length > 0
    ? '안전한 학급 사용을 위해 비밀번호를 설정해주세요.'
    : ''
})

watch(pwd, () => {
  resetValidStateMap('pwd')
  if (rePwd.value.length > 0) {
    //비밀번호 확인 값이 있는데 pwd 값이 변경될 경우
    checkValidation('rePwd', rePwd.value)
  }
})
watch(rePwd, () => {
  resetValidStateMap('rePwd')
})

const setValidStateMap = ({
  id,
  err,
  valid,
  msg
}: {
  id: string
  err?: boolean
  valid?: boolean
  msg?: string
}) => {
  if (typeof err !== 'undefined') validStateMap[id].err = err
  if (typeof valid !== 'undefined') validStateMap[id].valid = valid
  if (typeof msg !== 'undefined') validStateMap[id].msg = msg
}

const resetValidStateMap = (id: string) => {
  setValidStateMap({ id, err: false, valid: false, msg: '' })
}

const emit = defineEmits(['update:pwdToken', 'nextPhase'])

const onKeyUp = (e: KeyboardEvent) => {
  const input = e.target as HTMLInputElement

  if (input) {
    const { value, id } = input
    checkValidation(id, value)
  }
}

const checkValidation = (id: string, value: string) => {
  if (value.length === 0) return
  setValidStateMap({ id, err: false, valid: false })

  const { pwdRule, pwdSame } = PWD_VALIDATOR
  if (!pwdRule(value)) {
    setValidStateMap({
      id,
      err: true,
      msg: PWD_MSG_OBJ.PWD_RULE
    })
    return
  } else {
    setValidStateMap({
      id,
      msg: PWD_MSG_OBJ.PWD_VALID
    })
  }

  if (id === 'rePwd') {
    if (!pwdSame(pwd.value, value)) {
      setValidStateMap({
        id,
        err: true,
        msg: PWD_MSG_OBJ.PWD_NOT_SAME
      })

      return
    } else {
      setValidStateMap({
        id,
        msg: PWD_MSG_OBJ.PWD_SAME
      })
    }
  }
  setValidStateMap({ id, err: false, valid: true })
}

// 변경하기
const updatePassword = async () => {
  try {
    let resp
    if (hasProfile.value) {
      resp = await $api.auth.changePassword({
        password: nowPwd.value,
        newPassword: pwd.value
      })
    } else if (props.pwdToken) {
      resp = await $api.account.resetPasswordByToken({
        token: props.pwdToken,
        password: pwd.value
      })
    } else if (props.agreedTerms.length > 0) {
      resp = await $api.profile.createProfile({
        password: pwd.value,
        terms: props.agreedTerms
      })
    }
    if (resp) {
      const { code } = resp
      if (resp.code === 0) {
        if (props.agreedTerms.length > 0) {
          // 학급계정 최초 로그인
          setProfile(resp.data)
          setTerms([])
          emit('nextPhase', COMPLETE_PHASE)
        } else {
          goToBack()
          setTimeout(() => {
            $toast.success('비밀번호가 변경되었습니다.', {
              toastId: 'success-change'
            })
          }, 100)
        }
      } else {
        if (ERR_MSG_OBJ[code]) {
          $toast.warning(ERR_MSG_OBJ[code], {
            toastId: 'warning-change'
          })
          return
        }

        $toast.error(
          `일시적인 오류로 비밀번호 변경에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
          {
            toastId: 'error-change'
          }
        )
      }
    }
  } catch (e) {
    console.error(e)
    $toast.error(
      `일시적인 오류로 비밀번호 변경에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
      {
        toastId: 'error-change'
      }
    )
  }
}
const goToBack = () => {
  if (props.pwdToken) {
    emit('update:pwdToken', '') // 성공
  } else {
    if (fromPath.value) {
      router.go(-1)
    } else {
      router.push('/settings')
    }
  }
}

defineExpose({
  updatePassword
})
</script>

<style lang="scss">
.form_pwd {
  .txt_info {
    display: flex;
    margin-top: 8px;
    color: $gray0;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: -0.64px;

    @include tablet {
      .txt_info {
      }
    }
    @include mobile {
      font-size: 15px;

      letter-spacing: -0.6px;
    }
  }
}
</style>
